import React from "react"
import * as styles from "./TeamMember.module.scss"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

export default function TeamMember({ name, role, image }) {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <GatsbyImage
          image={getImage(image)}
          className={styles.avatarImg}
          alt={name + " avatar gold"}
        />
        <p className={styles.memberName}>{name}</p>
        <p className={styles.memberRole}>{role}</p>
      </div>
    </div>
  )
}
