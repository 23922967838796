import React, { useState, useEffect } from "react"
import FaqQuestion from "./FaqQuestion"
import * as styles from "./FaqQuestion.module.scss"
import parse from "html-react-parser"
import { FaqService } from "../../services/FaqService"

export default function FaqQuestionContainer({ children }) {
  const [questions, setQuestions] = useState([])
  const [activeQuestionState, setActiveQuestionState] = useState(null)

  useEffect(() => {
    const faqService = new FaqService()
    const questions = faqService.mainQuestions
    setQuestions(questions)
  }, [activeQuestionState])

  function setActiveQuestion(ref) {
    ref !== activeQuestionState
      ? setActiveQuestionState(ref)
      : setActiveQuestionState(null)
  }

  return (
    <div className={styles.container}>
      <ul className={styles.faq}>
        {questions.map(q => (
          <FaqQuestion
            key={q.question.replaceAll(" ", "_")}
            question={q.question}
            activeQuestion={activeQuestionState}
            setActiveQuestion={setActiveQuestion}
            active={activeQuestionState === q.question.replaceAll(" ", "_")}
          >
            {parse(q.answer)}
          </FaqQuestion>
        ))}
      </ul>
    </div>
  )
}
